<template>
  <div>
    <PageHeader />
    <b-button-group class="mb-2">
      <b-button
        v-for="prop in menu"
        :key="prop.name"
        :variant="currentRouteName === prop.path ? '' : 'light'"
        @click="navigateRoute(prop.path)"
      >
        {{ prop.name }}
      </b-button>
    </b-button-group>
    <div>
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'

export default {
  page: {
    title: 'ข้อมูลผู้เล่น',
  },
  components: {},
  data() {  },
  computed: {
    ...mapState({
      isFetchingPlayer: (state) => state.player.isFetchingPlayer,
      isFetchingPlayerAf: (state) => state.player.isFetchingPlayerAf,
      isFetchingPlayerCashback: (state) =>
        state.player.isFetchingPlayerCashback,
    }),
    ...mapGetters(['playerDetail', 'playerCashbackLogs', 'playerAfLogs']),
    playerId() {
      return this.$route.params.id
    },
    currentRouteName() {
      return this.$route.fullPath
    },
    menu() {
      return [
        {
          name: 'ข้อมูลเบื้องต้น',
          path: `/players/detail/${this.playerId}/info`,
        },
        {
          name: 'ตั้งค่าการวางเดิมพัน',
          path: `/players/detail/${this.playerId}/bet-limit`,
        },
      ]
    }
  },
  methods: {
    ...mapActions([
      'fetchPlayer',
      'deletePlayer',
      'fetchPlayerAfLogs',
      'fetchPlayerCashbackLogs',
    ]),
    navigateRoute(path) {
      if (path !== this.$route.fullPath) {
        this.$router.push(path)
      }
    },
  },
}
</script>
